/**
 * @name 테이블리스트
 * @description
 */
// contents
import {EditButton, ShortcutButton} from 'pages/common';
import {useRoutes} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {Link} from 'react-router-dom';

type Props = {
  item: any;
  index: number;
};
export default function Contents({item, index}: Props) {
  const {navigate} = useRoutes();

  return (
    <Content key={`${item}_${index}`}>
      {/* NO */}
      <td>{item?.idx}</td>
      {/* 사이트ID */}
      <td>
        <Link to={`/site/view/${item?.siteId}?mode=view`}>{item?.siteId}</Link>
      </td>
      {/* 사이트이름 */}
      <td>
        <Link to={`/site/view/${item?.siteId}?mode=view`}>{item?.name}</Link>
      </td>
      {/* 수요기업이름 */}
      <td>{item?.demandCompany}</td>
      {/* 사이트주소 */}
      <td>{item?.siteAddress}</td>
      {/* 위도/경도 */}
      <td>
        {item?.siteLatitude} / {item?.siteLongitude}
      </td>
      {/* 사업유형 */}
      <td>{item?.businessType && item?.businessType.join(', ')}</td>
      {/* 담당자 */}
      <td>{item?.managerName}</td>
      {/* 담당자연락처 */}
      <td>{item?.managerPhone}</td>
      {/* 계통구성도 */}
      <td>
        <ShortcutButton
          onClick={() => {
            item?.grid && navigate(`/site/view/${item?.siteId}?tabSelected=2`);
          }}
          disabled={!item?.grid}
        />
      </td>
      {/* dummy */}
      <td></td>
      {/* 편집 */}
      <td style={{textAlign: 'left'}}>
        <EditButton
          onClick={() => {
            navigate(`/site/view/${item?.siteId}?mode=edit`);
          }}
        />
      </td>
    </Content>
  );
}
const Content = styled.tr`
  &:hover {
    background-color: ${Color.gray90};
  }
`;
